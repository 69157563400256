export default theme => ({
	root: {
		flexDirection: 'column',
		minHeight: '100%',
		paddingTop: '35px',
		paddingBottom: '35px', // 80 px navbar size - 1px top border
		zIndex: theme.zIndex.drawer
	},
	logoWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '50px',
		flexShrink: 0
	},
	menuButtonOpen: {
		right: '-55px!important',
		visibility: 'visible!important'
	},
	logoLink: {
		fontSize: 0,
		textDecoration: 'none',
		margin: 'auto'
	},
	logoImage: {
		width: '30px',
		margin: 'auto',
		marginBottom: 55,
		marginTop: 10,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			width: '25px',
			marginTop: 0
		}
	},
	navLink: {
		textDecoration: 'none'
	},
	navSubLink: {
		textDecoration: 'none',
		color: theme.palette.text.disabled
	},
	activeListItem: {
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
		'&:before': {
			top: 0,
			right: 0,
			width: 3,
			bottom: 0,
			content: "''",
			position: 'absolute',
			backgroundColor: theme.palette.secondary.main
		},
		'& $listItemIcon': {
			color: theme.palette.secondary.main
		}
	},
	activeSubListItem: {
		fontWeight: 800,
		color: theme.palette.secondary.main,
		'& $listItemIcon': {
			color: theme.palette.secondary.main
		}
	},
	listItemIcon: {
		marginRight: '10px',
		opacity: 0.8,
		maxWidth: '18px'
	},
	listItemArrow: {
		marginLeft: '-10px',
		width: 16
	},
	activeDropdownLink: {
		background: theme.palette.background.darker
	},
	listDivider: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		backgroundColor: theme.palette.dividerDark
	},
	sidebarCollapse: {
		borderBottom: `1px solid ${theme.palette.divider}`
	}
});
