import React from 'react';
// Material core components
// Material core styles
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

// Dependencies
import { enqueueSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import { inviteUser, fetchMyContacts } from '../../../redux/actions/profileScreenActions';
// Actions

const styles = theme => ({
	drawerPaper: {
		zIndex: 1190,
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		[theme.breakpoints.up('md')]: {
			width: '50%'
		},
		[theme.breakpoints.up('lg')]: {
			width: '35%'
		}
	},
	radioGrid: {
		display: 'inline-flex'
	}
});

class InviteUser extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			userType: 'user',
			isLoading: false
		};
	}

	componentDidUpdate(prevProps) {
		if (!this.state.isLoading) return;
		if (this.props.inviteError && prevProps.inviteError !== this.props.inviteError) {
			enqueueSnackbar(this.props.inviteError, { variant: 'error' });
			this.setState({
				isLoading: false
			});
		}
		if (this.props.userInvited && prevProps.userInvited !== this.props.userInvited) {
			enqueueSnackbar(this.props.inviteMessage, { variant: 'info' });
			this.props.fetchMyContacts();
			this.setState({
				isLoading: false
			});
			this.props.toggleInviteUser();
		}
	}

	onSubmitForm = event => {
		this.setState({
			isLoading: true
		});
		this.props.inviteUser({
			existingUser: this.props.cognitoUser,
			invitedUser: { email: this.state.email, userType: this.state.userType }
		});

		event.preventDefault();
	};

	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value
		});
	};

	handleRadioChange = (event, value) => {
		this.setState({ userType: value });
	};

	render() {
		const { classes, openDialog, toggleInviteUser } = this.props;
		return (
			<Dialog
				open={openDialog}
				onClose={this.props.handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle>
					Invite User
					<IconButton
						aria-label="close"
						sx={{ position: 'absolute', right: 10, top: 10 }}
						onClick={toggleInviteUser}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<form autoComplete="off" onSubmit={this.onSubmitForm}>
					<DialogContent dividers>
						<DialogContentText>
							Enter the email address in the form below and user will receive an
							invitation
						</DialogContentText>
						<br />
						<Grid container>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									autoFocus
									id="email"
									type="email"
									data-test="email-input"
									label="Email Address:"
									value={this.state.email}
									onChange={this.handleChange}
									margin="normal"
									variant="outlined"
									style={{ width: '100%' }}
								/>
							</Grid>
							<Grid item xs={12} sm={6} className={classes.radioGrid}>
								<RadioGroup
									aria-label="type"
									name="userType"
									value={this.state.userType}
									onChange={this.handleRadioChange}
									row
									style={{ margin: 'auto' }}
								>
									<FormControlLabel
										value="user"
										control={<Radio color="primary" />}
										label="User"
										labelPlacement="top"
									/>
									<FormControlLabel
										value="admin"
										control={<Radio color="primary" />}
										label="Admin"
										labelPlacement="top"
									/>
								</RadioGroup>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							variant="outlined"
							loading={this.state.isLoading}
							data-test="send-invite-button"
							type="submit"
							color="primary"
						>
							Send Invitation
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

function mapStateToProps(state) {
	return {
		cognitoUser: state.auth.cognitoUser,
		inviteError: state.profile?.error,
		userInvited: state.profile?.userInvited,
		inviteMessage: state.profile?.message
	};
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators(
			{
				inviteUser,
				fetchMyContacts
			},
			dispatch
		),
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InviteUser));
