import {
	CREATE_TENANT,
	CREATE_TENANT_ERROR,
	CREATE_TENANT_PENDING,
	GET_TENANT_STATUS,
	GET_USER
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_USER: {
			const returnProps = {
				...state,
				permissions: action.payload?.data?.permissions,
				permissionsStatus: action.payload?.status
			};
			return returnProps;
		}
		case CREATE_TENANT: {
			console.log(action.payload);
			const returnProps = {
				...state,
				tenant: {
					...action.payload?.response
				}
			};
			return returnProps;
		}
		case CREATE_TENANT_PENDING: {
			const returnProps = {
				...state,
				tenant: null,
				tenantCreationError: null
			};
			return returnProps;
		}
		case CREATE_TENANT_ERROR: {
			const returnProps = {
				...state,
				tenant: null,
				tenantCreationError: action.payload
			};
			return returnProps;
		}
		case GET_TENANT_STATUS: {
			const returnProps = {
				...state,
				tenant: {
					...action.payload?.response
				}
			};
			return returnProps;
		}
		default:
			return state;
	}
}
