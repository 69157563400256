import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';
import {
	TOGGLE_CHATBOT_RIGHT_SIDEBAR,
	CHATBOT_SESSION_ID,
	UPDATE_PROMPT,
	FETCH_PROMPT,
	BOT_MESSAGE_LOADING,
	SAVE_CHAT_HISTORY,
	HISTORY_LOADING,
	SET_CHAT_SESSION,
	SET_IS_STREAMING,
	SET_AGENT_LIST,
	GET_AGENT_SESSION_LIST_ERROR,
	GET_AGENT_SESSION_LIST_PENDING,
	GET_AGENT_SESSION_LIST_SUCCESS,
	CREATE_AGENT_SESSION_ERROR,
	CREATE_AGENT_SESSION_SUCCESS,
	CREATE_AGENT_SESSION_PENDING,
	DEACTIVATE_AGENT_SESSION,
	RENAME_CONVERSATION_THREAD,
	RENAME_CONVERSATION_ERROR,
	RENAME_CONVERSATION_PENDING
} from './types';

export const toggleChatbotRightSidebar = data => dispatch => {
	dispatch({
		type: TOGGLE_CHATBOT_RIGHT_SIDEBAR,
		payload: {
			data
		}
	});
};

export const renameConversationThread = (data, conversationId) => async dispatch => {
	dispatch({
		type: RENAME_CONVERSATION_PENDING
	});

	try {
		const response = await API.put(
			CONFIGURATION.AWS_API_NAME,
			`/agents/agent-conversations/rename/${conversationId}?agent_id=${data.agent_id}&conversation_name=${data.conversation_name}`
		);
		dispatch({
			type: RENAME_CONVERSATION_THREAD,
			payload: response
		});
	} catch (err) {
		dispatch({
			type: RENAME_CONVERSATION_ERROR,
			payload: 'Error while trying to rename conversation. Please try again.'
		});
	}
};

export const setChatbotConnected = isConnected => dispatch => {
	const payload = isConnected ? { isConnected } : { isConnected, initialized: false };
	dispatch({
		type: CHATBOT_SESSION_ID,
		payload: { ...payload }
	});
};

export const fetchPrompt = () => async (dispatch, getState) => {
	let response = null;
	const state = getState();
	const payload = {
		token: state.auth.cognitoUser.signInUserSession.accessToken.jwtToken,
		prompt_name: 'question_prompt'
	};

	try {
		response = await API.post(CONFIGURATION.AWS_API_NAME, '/get_prompt', payload);
	} catch (err) {
		console.log(err);
	}

	dispatch({
		type: FETCH_PROMPT,
		payload: {
			response
		}
	});
};

export const updatePrompt = editedText => async dispatch => {
	let configResponse = null;
	const requestData = {
		response: true,
		body: editedText
	};

	try {
		configResponse = await API.put(CONFIGURATION.AWS_API_NAME, '/chatbot/prompt', requestData);
	} catch (err) {
		configResponse = err.response ?? { status: 500 };
	}

	dispatch({
		type: UPDATE_PROMPT,
		payload: configResponse
	});
};

export const getAgentList = () => async dispatch => {
	let response = null;

	try {
		response = await API.get(CONFIGURATION.AWS_API_NAME, '/agents/agents', { response: true });
	} catch (err) {
		response = { status: 500 };
	}

	dispatch({
		type: SET_AGENT_LIST,
		payload: response
	});
};

export const getAgentSessionList = agentId => async dispatch => {
	dispatch({
		type: GET_AGENT_SESSION_LIST_PENDING
	});

	try {
		const response = await API.get(
			CONFIGURATION.AWS_API_NAME,
			`/agents/agent-conversations/${agentId}`,
			{
				response: true
			}
		);

		dispatch({
			type: GET_AGENT_SESSION_LIST_SUCCESS,
			payload: { list: response?.data, agentId }
		});
	} catch (err) {
		dispatch({
			type: GET_AGENT_SESSION_LIST_ERROR,
			payload: err.response?.data?.detail
		});
	}
};

export const createAgentSession = agentId => async dispatch => {
	dispatch({
		type: CREATE_AGENT_SESSION_PENDING
	});
	try {
		const response = await API.post(
			CONFIGURATION.AWS_API_NAME,
			`/agents/agent-conversations/${agentId}`
		);

		dispatch({
			type: CREATE_AGENT_SESSION_SUCCESS,
			payload: { createdSession: response, agentId }
		});
	} catch (err) {
		dispatch({
			type: CREATE_AGENT_SESSION_ERROR,
			payload: err.response
		});
	}
};

export const deactivateAgentSession = (agentId, historyId) => async dispatch => {
	try {
		await API.post(CONFIGURATION.AWS_API_NAME, `/chat-history/${historyId}/deactivate`);

		dispatch({
			type: DEACTIVATE_AGENT_SESSION,
			payload: { historyId, agentId }
		});
	} catch (err) {
		dispatch({
			type: GET_AGENT_SESSION_LIST_ERROR,
			payload: 'Error while trying to delete the session. Please try again.'
		});
	}
};

export const updateMessageWithToken = (event, isFinal) => async (dispatch, getState) => {
	const state = getState();
	const agentId = event.agent_id;
	const conversationId = event.conversation_id;
	const messageHistory = state.chatbot?.history;
	const currentMessages = messageHistory?.[agentId]?.[conversationId]?.messages;

	if (!isFinal && !state.chatbot.isStreaming) {
		dispatch(setIsStreaming(true));
	}

	if (currentMessages?.length) {
		const targetId = isFinal ? event.updated_item?.timestamp : event.message_id;
		const messageToUpdateIndex = currentMessages.findIndex(
			message => message.timestamp === targetId
		);
		const updatedMessage = {
			...currentMessages[messageToUpdateIndex],
			payload: isFinal ? event.updated_item?.payload : JSON.stringify(event.message)
		};

		const updatedMessages = [...currentMessages];
		updatedMessages.splice(messageToUpdateIndex, 1, updatedMessage);

		dispatch(
			saveChatHistory({
				...messageHistory,
				[agentId]: {
					...messageHistory?.[agentId],
					[conversationId]: {
						...messageHistory?.[agentId]?.[conversationId],
						messages: updatedMessages
					}
				}
			})
		);

		if (isFinal) {
			dispatch(setIsStreaming(false));
			dispatch(setBotMessageLoading(false));
		}
	}
};

export const updateHistory = snapshot => async (dispatch, getState) => {
	const state = getState();
	const agentId = snapshot.agent_id;
	const conversationId = snapshot.conversation_id;
	const messageHistory = state.chatbot?.history;
	let newList = snapshot.messages ?? snapshot.history_snapshot.messages;
	if (messageHistory?.[agentId]?.[conversationId]?.messages?.length) {
		newList = filterAndSortMessages([...newList], agentId, conversationId, messageHistory);
	}
	dispatch(setHistoryLoading(false));
	dispatch(setBotMessageLoading(false));
	dispatch(
		saveChatHistory({
			...messageHistory,
			[agentId]: {
				...messageHistory?.[agentId],
				[conversationId]: {
					...snapshot,
					messages: newList
				}
			}
		})
	);
};

export const setBotMessageLoading = isLoading => async dispatch => {
	dispatch({
		type: BOT_MESSAGE_LOADING,
		payload: isLoading
	});
};

export const setHistoryLoading = isLoading => async dispatch => {
	dispatch({
		type: HISTORY_LOADING,
		payload: isLoading
	});
};

export const saveChatHistory = history => async dispatch => {
	dispatch({
		type: SAVE_CHAT_HISTORY,
		payload: history
	});
};

export const setChatSessionId = sessionId => dispatch => {
	dispatch({
		type: SET_CHAT_SESSION,
		payload: sessionId
	});
};

export const setIsStreaming = isStreaming => dispatch => {
	dispatch({
		type: SET_IS_STREAMING,
		payload: isStreaming
	});
};

const filterAndSortMessages = (upcomingMessages, agentId, conversationId, messageHistory) => {
	const currentMessages = messageHistory?.[agentId]?.[conversationId]?.messages ?? [];
	return [...upcomingMessages, ...currentMessages]
		.sort((a, b) => a.index - b.index)
		.filter((v, i, x) => x.findIndex(v2 => v2.index === v.index) === i)
		.filter(message => message.index || message.index === 0);
};
