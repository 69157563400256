import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Alert, Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

export default function RegisterForm({ credentials }) {
	const { resendConfirmationCode, confirmSignup, login } = useAuth();
	const isMountedRef = useIsMountedRef();
	const { enqueueSnackbar } = useSnackbar();

	console.log(credentials);

	const ValidationSchema = Yup.object().shape({
		code: Yup.string().required('Verification code is required')
	});

	const formik = useFormik({
		initialValues: {
			code: ''
		},
		validationSchema: ValidationSchema,
		onSubmit: async (values, { setErrors, setSubmitting }) => {
			try {
				console.log(credentials);

				const response = await confirmSignup(values.code, credentials.email);

				console.log('after confirmation', response);

				if (response === 'SUCCESS') {
					enqueueSnackbar('Email confirmed!', {
						variant: 'success'
					});

					try {
						await login(credentials.email, credentials.password);
					} catch (error) {
						enqueueSnackbar(error.message, { variant: 'error' });
					}
				}

				if (isMountedRef.current) {
					setSubmitting(false);
				}
			} catch (error) {
				console.error(error);
				if (isMountedRef.current) {
					setErrors({ afterSubmit: error.message });
					setSubmitting(false);
				}
			}
		}
	});

	const handleResendVerificationCodeClick = async () => {
		try {
			await resendConfirmationCode();
			enqueueSnackbar('Verification code sent', { variant: 'success' });
		} catch (error) {
			console.error(error);
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

	return (
		<FormikProvider value={formik}>
			<Form style={{ width: '100%' }} autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					{errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

					<TextField
						fullWidth
						type="text"
						label="Verification code"
						{...getFieldProps('code')}
						error={Boolean(touched.code && errors.code)}
						helperText={touched.code && errors.code}
					/>

					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isSubmitting}
					>
						Verify
					</LoadingButton>
					<Box>
						<Typography variant="body2">
							Didn't get the code?{' '}
							<Button onClick={handleResendVerificationCodeClick}>Resend</Button>
						</Typography>
					</Box>
				</Stack>
			</Form>
		</FormikProvider>
	);
}
