import { combineReducers } from 'redux';
import AuthReducer from './authReducer';
import ReduxFormReducer from './reduxFormReducer';
import profileScreenReducer from './profileScreenReducer';
import layoutReducer from './layoutReducer';
import commonReducer from './commonReducer';
import chatbotReducer from './chatbotReducers';
import docManagerReducer from './documentManagerReducer';
import superadminReducer from './superadminReducer';
import folderManager from '../slices/folderManager';
import agentManager from '../slices/agentManager';
import permissions from '../slices/permissions';

const appReducer = combineReducers({
	form: ReduxFormReducer,
	auth: AuthReducer,
	profile: profileScreenReducer,
	layout: layoutReducer,
	common: commonReducer,
	chatbot: chatbotReducer,
	docManager: docManagerReducer,
	superadmin: superadminReducer,
	folders: folderManager,
	permissions,
	agentManager
});

// clear all session on unauth_user action
const rootReducer = (state, action) => {
	if (action.type.toUpperCase() === 'UNAUTH_USER') {
		const { routing } = state;
		state = { routing };
	}
	return appReducer(state, action);
};

export default rootReducer;
